import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../components/loader/Loader";

import "../styles/marketing-warmup-questionnaire.scss";

const WarmUpQuestionaire = () => {
  const [state, setState] = useState("active"); // active/loading/error/success

  return (
    <Layout>
      <SEO title="Warm Up Questionaire" />
      <div className="marketing-warmup-questionnaire-page">
        <Container className="hero-section container">
          <Row>
            <Col className="content-container column centered-text">
              <h1 className="white-text">Warm-up Questionnaire</h1>
              <p className="white-text subtext">
                Tell us alittle bit about yourself and your company!
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="container white-section">
          <Row>
            <Col>
              <div className="form-container">
                {state !== "success" && (
                  <form
                    id="warmup-form"
                    name="warmup-questionnaire-form"
                    method="POST"
                    netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={e => {
                      e.preventDefault();

                      setState("loading");

                      var formData = new FormData(
                        document.getElementById("warmup-form")
                      );

                      fetch("/", {
                        method: "POST",
                        body: formData,
                      })
                        .then(res => {
                          if (res.status === 200) {
                            setState("success");
                          } else {
                            setState("error");
                          }
                        })
                        .catch(error => {
                          setState("error");
                        });
                    }}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="warmup-questionnaire-form"
                    />
                    <input type="hidden" name="bot-field" />

                    <div className="field">
                      <label htmlFor="name">What's your name?</label>
                      <input type="text" name="name" required />
                    </div>

                    <div className="field">
                      <label htmlFor="title">What's your title?</label>
                      <input type="text" name="title" required />
                    </div>

                    <div className="field">
                      <label htmlFor="email">
                        What's your work email address?
                      </label>
                      <input type="text" name="email" required />
                    </div>

                    <div className="field">
                      <label htmlFor="company-name">
                        What's your company name?
                      </label>
                      <input type="text" name="company-name" required />
                    </div>

                    <div className="field">
                      <label htmlFor="website">What's your website URL?</label>
                      <input type="text" name="website" required />
                    </div>

                    <div className="field">
                      <label htmlFor="address">
                        Company Address (If more than one, add those as well)
                      </label>
                      <textarea name="address" required></textarea>
                    </div>

                    <div className="field">
                      <label>
                        What IT Services do you offer and what priority would
                        you like to offer them? Please number 1-12 with 1 being
                        the 1st month and 12 being the last.
                      </label>
                      <ul className="services">
                        <li>
                          <input type="text" name="bdr" />
                          Backup and Disaster Recovery
                        </li>
                        <li>
                          <input type="text" name="unified-communications" />
                          Unified Communication
                        </li>
                        <li>
                          <input type="text" name="managed-services" />
                          Managed Services
                        </li>
                        <li>
                          <input type="text" name="virtualization" />
                          Virtualization
                        </li>
                        <li>
                          <input type="text" name="cybersecurity" />
                          Cybersecurity
                        </li>
                        <li>
                          <input type="text" name="consulting" />
                          Consulting
                        </li>
                        <li>
                          <input type="text" name="cloud-computing" />
                          Cloud Computing
                        </li>
                        <li>
                          <input type="text" name="professional-services" />
                          Professional Services
                        </li>
                        <li>
                          <input type="text" name="managed-security" />
                          Managed Security
                        </li>
                        <li>
                          <input type="text" name="regulatory-compliance" />
                          Regulatory Compliance
                        </li>
                        <li>
                          <input type="text" name="voip" />
                          VoIP
                        </li>
                        <li>
                          <input type="text" name="it-support" />
                          IT Support
                        </li>
                      </ul>
                    </div>

                    <div className="field">
                      <label htmlFor="primary-color">
                        What's your company's primary color? (ex. Hex code
                        #1F54A7)
                      </label>
                      <input type="text" name="primary-color" required />
                    </div>

                    <div className="field">
                      <label htmlFor="secondary-colors">
                        Does your company use any other color(s)? (ex. Hex code
                        #1F54A7)
                      </label>
                      <input type="text" name="secondary-colors" />
                    </div>

                    <div className="field">
                      <label htmlFor="font">
                        Does the company use a specific font(s)? List below
                        (optional)
                      </label>
                      <input type="text" name="font" required />
                    </div>

                    <div className="field">
                      <label htmlFor="logo">
                        Upload company logo (.ai, .eps, .pdf, .svg, .psd or any
                        high res file)
                      </label>
                      <input type="file" name="logo" required />
                    </div>

                    <div className="field">
                      <label htmlFor="from-name">
                        Who would you like emails to come from? (ex. Employee's
                        name or Company Name)
                      </label>
                      <input type="text" name="from-name" required />
                    </div>

                    <div className="field">
                      <label htmlFor="from-email">
                        What email address would you like emails to come from?
                        (ex. steve@ or company@)
                      </label>
                      <input type="text" name="from-email" required />
                    </div>

                    <div className="field">
                      <label htmlFor="forward">
                        What email would you like form submissions to go to?
                        (ex. sales@ or steve@)
                      </label>
                      <input type="text" name="forward" required />
                    </div>

                    <div className="field">
                      <label htmlFor="sales-team">
                        Do you have a sales team? If yes, how many on the team?
                      </label>
                      <input type="text" name="sales-team" required />
                    </div>

                    <div className="field">
                      <label htmlFor="process">
                        Do you have a sales process?
                      </label>
                      <input type="text" name="process" required />
                    </div>

                    <div className="field">
                      <label htmlFor="top-emails">
                        What are the top emails that you use in your sales
                        process? (e.g. introduction email, follow up email,
                        onboarding email)
                      </label>
                      <input type="text" name="top-emails" required />
                    </div>

                    {state === "error" && (
                      <div className="warning-box">
                        Looks like there was a problem submitting your form.
                        Please ensure all required form fields are filled out
                        and try again.
                      </div>
                    )}

                    {state === "loading" && <Loader />}

                    {state !== "loading" && state !== "success" && (
                      <button type="submit" className="pink-button">
                        Take the First Step
                      </button>
                    )}
                  </form>
                )}

                {state === "success" && (
                  <div className="success-box">
                    Thank you for finishing the questionnaire. We'll be in touch
                    shortly!
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default WarmUpQuestionaire;
